import React from "react"
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import { theme } from "gatsby-theme-firebase"
import { CookiesProvider } from "react-cookie"
import SEO from "../components/seo"


theme.colors.primary = "#110e2d"
theme.colors.secondary = "#c4ae94"

const Layout = ({ children }) => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  let materialTheme = React.useMemo(() =>
    createMuiTheme({
      breakpoints: {},
      palette: {
        primary: {
          main: "#110E2D",
        },
        secondary: {
          main: "#DBBFA5",
        },
        // type: prefersDarkMode ? "dark" : "light",
      },
      typography: {
        fontFamily: ["Lato", "EB Garamond"].join(","),
      },
    })
  )
  materialTheme = responsiveFontSizes(materialTheme)
  return (
    <>
      <SEO />
      <CookiesProvider>
        <ThemeProvider theme={materialTheme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </CookiesProvider>
    </>
  )
}

export default Layout
