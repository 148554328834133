module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-firebase/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"QR Stories","short_name":"QRStories","start_url":"/app","description":"Une façon plus intelligente de se souvenir de ceux que nous avons perdus et de s'assurer qu'ils ne sont pas perdus dans le temps","background_color":"#110E2D","theme_color":"#110E2D","display":"standalone","icon":"static/logo@1x.png","lang":"fr","cache_busting_mode":"none","localize":[{"start_url":"/en/","lang":"en"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"fr","configPath":"C:\\Users\\adria\\OrNechama\\web\\i18n\\config.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
