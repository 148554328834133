/* eslint-disable  */
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import config from "gatsby-theme-firebase/src/firebase/config";

const isBrowser = typeof window !== "undefined";

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

let auth;

const firestore = firebase.firestore();

if (isBrowser) {
    auth = firebase.auth();
    // console.debug("Persistance enabled!")
    // firestore.enablePersistence()
    //     .catch((err) => {
    //         console.error("Persistence failure:", err)
    //     });
}


export { auth, firestore };
export default firebase;